<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5">
          <div class="col-12">
            <UserTripMenu />
          </div>
        </div>
        <div class="actions-button-wrap">
          <router-link :to="{ name: 'user-trip-modify', params: { id: this.$route.params.id } }"
                       class="action-buttons">
            <i class="icon-edit" />
            <div class="input-name">
              <p>Change your reservation</p>
              <p><small>Request to change your dates or number of guests</small></p>
            </div>
          </router-link>
          <router-link :to="{ name: 'user-trip-cancellation', params: { id: this.$route.params.id } }"
                       class="action-buttons">
            <i class="icon-close" />
            <div class="input-name">
              <p>Cancel reservation</p>
              <p><small>If you cancel may charges will be applied</small></p>
            </div>
          </router-link>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserEditAndCancelTrip',
  components: {
    ProfileMenu,
    UserTripMenu,
    BookingInfo
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
